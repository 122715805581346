.form-container {
    padding-top: 50px;
    max-width: 900px;
    padding-bottom: 30px;
    margin: 50px auto;
    border-radius: 8px;
    background-color: white;
    border-radius: 10px
}

.form-control {
    min-height: 60px;
    min-width: 50px !important;
    display: flex;
    align-items: center;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
    width: 100%;
    padding-left: 10px;
}

.submit {
    text-align: center;
    background-color: #ffcf0d;
    font-weight: bold !important;
    color: black !important;
}

.heading {
    text-align: center;
}

.tag {
    color: gray !important;
    font-size: 15px !important;
}
