body {
    background-image: url("../../Utils/images/backImage.png");
}

.my-login-page .brand {
    width: 90px;
    height: 90px;
    overflow: hidden;
    border-radius: 50%;
    margin: 40px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 1;
}

a {
    color: black;
}



a:hover {
    color: grey;
    font-weight:bold;
}

.button-login {
    margin-top: 30px;
    margin: 15px;
    width: 90%;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: black;
    background-color: #ffcf0d;
    height: 40px;
}

    .button-login:hover {
        background-color: #c9a30a;
    }

.my-login-page .brand img {
    width: 100%;
}

.my-login-page .card-wrapper {
    width: 400px;
}

.my-login-page .card {
    border-color: transparent;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

    .my-login-page .card.fat {
        padding: 10px;
        height: 470px;
    }

    .my-login-page .card .card-title {
        margin-bottom: 30px;
    }

.my-login-page .form-control {
    border: 2px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    color: black;
}

    .my-login-page .form-control:focus {
        border: 2.3px solid #ffcf0d;
        border-radius: 5px;
        color: black;
    }

.my-login-page .form-group label {
    width: 100%;
    font-size: 14px;
}

.my-login-page .btn.btn-block {
    padding: 12px 10px;
}

.my-login-page .footer {
    margin-top: 20px;
    color: black;
    text-align: center;
}

input[type="radio"] {
    border: 0px;
    width: 60%;
    height: 2em;
    color: black;
}

input {
    color: black;
}

@media screen and (max-width: 425px) {
    .my-login-page .card-wrapper {
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 320px) {
    .my-login-page .card.fat {
        padding: 0;
    }

        .my-login-page .card.fat .card-body {
            padding: 15px;
        }
}



.singin-cont {
    max-width: 500px;
    background-color: #ffcf0d;
    padding-top: 50px;
    padding-bottom: 30px;
    margin: 50px auto;
    background: #ffcf0d;
    border-radius: 8px;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
    background-image: url("../../Utils/dummy/profile_cover.png");
}

.singin-row {
    /*background-color: #ffcf0d !important;*/
}
