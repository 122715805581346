#team {
    text-align: center;
}

.team-column {
    padding: 3% 2%;
    text-align: left;
}

.card {
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
}

.member-tree {
    padding: 20px;
    text-align: center !important;
}

.bold-text {
    text-align: center !important;
}

.button {
    background-color: white !important;
    color: gray !important;
    padding-top: 10px !important;
}

.manage-team-container {
    padding-top: 50px !important;
    max-width: 200% !important;
    padding-bottom: 30px !important;
    margin: 50px auto !important;
    background: #ffffff !important;
    border-radius: 8px !important;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3)
}


.form-control {
    color: black !important;
}
