.app-table {
  background-color: #fff;
  margin: 10px;
  padding: 10px 20px 10px 30px;
  width: auto;
  align-items: center;
  border-radius: 10px;
  font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans" "Helvetica Neue", sans-serif;
}


.progress-bar{
 background-color: #FECE0B;
}


.main-table tr:hover {
  background-color: #ffcf0d;
  color: black;
  cursor: pointer;
}

.main-table .ele-type button {
  width: 100px;
  height: 25px;
  border: none;
  border-radius: 30px;
  background-color: #FECE0B;
  color: #231B1B;
}

.main-table .ele-city {
  padding-left: 30px;
}


.check-button {
  
  height: 25px;
  border: none ;
  background-color: #FECE0B;
  color: #231B1B;
  margin-right:10px;
}

.main-table .ele-days {
  padding-left: 50px;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  /*padding-left: 50%;*/
}
.pagination > .active > a {
  background-color: #FECE0B;
  border-color: #ffcf0d;
  color: #231B1B;
}
.pagination > li > a {
  border: 1px solid #ffcf0d;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  color: #231B1B;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #ffcf0d;
  border-color: #ffcf0d;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: grey;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}



table.tableBodyScroll tbody {
  display: block;
  max-height: 800px;
  overflow-y: scroll;
}

table.tableBodyScroll thead, table.tableBodyScroll tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}