.profile-card {
  background-color: #fff;
  margin-top: 20px;
  padding: 10px;
  border-radius: 20px;
}

.profile-card .profile-button {
  width: 150px;
  height: 30px;
  border: none;
  border-radius: 30px;
  background-color: #5cb85c;
  color: black;
  cursor: pointer;
}

.non-verified {
  background-color: red;
}

.dialog {
  box-shadow: 0 8px 6px -6px black;
  position: absolute;
  left: -50%;
  top: -15%;
  right: 10%;
}

.image {
  width: 600px;
}


.modal-dialog {
    font-style: normal !important;
    text-align: left;
}

.modal-header {
    background-color: #ffcf0d !important;
    color: white !important;
}

.card {
    border-top: 0 !important
}

.sms {
    border-radius: 0 !important;
    padding: 7px !important;
    border-bottom: 1px solid black !important
}

.tag {
    color: black !important
}


