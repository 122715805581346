* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

.header {
    padding: 5px !important;
    margin: 7px !important;
}

.togglebtn:focus {
    color: white !important
}

.codetext {
    width: 30% !important
}

.pay-option:focus {
    color: #ffcf0d;
}

.paymentBody {
    color: black !important;
}

.choice-radio{
    color:black!important
}

input[type=radio] {
    border: 0px !important;
    max-width: 20% !important;
    height: 1em !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
}