body.my-login-page {
    background-color: #f7f9fb;
    font-size: 14px;
}

.my-login-page .brand {
    width: 90px;
    height: 90px;
    overflow: hidden;
    border-radius: 50%;
    margin: 40px auto;
    box-shadow: 0 4px 8px rgba(0,0,0,.05);
    position: relative;
    z-index: 1;
}

.my-login-page .brand img {
    width: 100%;
}

.my-login-page label:focus {
    color: black;
}

.my-login-page input{
    color: black;
     background-image: none;
}

.my-login-page .card-wrapper {
    width: 400px;
}

.my-login-page .card {
    box-shadow: 0 4px 8px rgba(0,0,0,.05);
}

.my-login-page .card.fat {
    padding: 10px;
    height: auto;
}

.my-login-page .card .card-title {
    margin-bottom: 30px;
}

.my-login-page .form-control {
    border-width: 2.3px;
}

.my-login-page .form-group label {
    width: 100%;
}

.my-login-page .btn.btn-block {
    padding: 12px 10px;
}

.my-login-page .footer {
    margin: 40px 0;
    color: #888;
    text-align: center;
}

@media screen and (max-width: 425px) {
    .my-login-page .card-wrapper {
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 320px) {
    .my-login-page .card.fat {
        padding: 0;
    }

    .my-login-page .card.fat .card-body {
        padding: 15px;
    }
}
.my-login-btn {
    background-color: #ffcf0d;
}

.reg-cont {
    max-width: 500px;
    background-color: #ffcf0d;
    padding-top: 50px;
    padding-bottom: 30px;
    margin: 50px auto;
    background: #ffcf0d;
    border-radius: 8px;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
    background-image: url("../../Utils/dummy/profile_cover.png");
}