.search-container {
  margin: none;
  padding-left: 200px;
  width: 100%;
  height: 60px;
  background-color: #fff;
 
}

.search-container button i {
  cursor: pointer;
}

.search-container button {
  border: none;
  background-color: #fff;
  color: #ccc;
}

.search-container input {
  border: none;
  height: 60px;
  width: 700px;
  padding-left: 20px;
  background-color:rgb(255, 255, 255);
  
}

.search-container input:focus,
button:focus {
  border: none;
  outline: none;
}

