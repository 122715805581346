
.post-container {
    padding-top: 50px !important;
    max-width: 60% !important;
    padding-bottom: 30px !important;
    margin: 50px auto !important;
    background: #ffffff !important;
    border-radius: 8px !important;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3) 
}

.form-control {
    color: black !important;
    min-height: 50px !important;
    min-width: 50px !important;
    display: flex !important;
    align-items: center !important;
    background-image: none !important;
    border: 0.4px solid #dddddd !important;
    border-radius: 10px !important;
}





.heading {
    font-weight: normal;
}

.submit {
    text-align: right;
    background-color: #ffcf0d !important;
    font-weight: bold !important;
    color: black !important;
}
.tag {
    color: gray !important;
    font-size: 15px !important;
    font-weight: bold!important;
}


/* Reset Select */
select {
    -webkit-appearance: none !important;
    -ms-appearance: none !important;
    appearance: none !important;
    outline: 0 !important;
    box-shadow: none !important;
    border: 0 !important;
    background: #2c3e50 !important;
  
    background-image: none !important;
    color: white !important;
}
/* Remove IE arrow */
    select::-ms-expand {
        display: none !important;
    }
/* Custom Select */
.select {
    position: relative !important;
    display: flex !important;
    width: 20em !important;
    height: 3em !important;
    line-height: 3 !important;
    background: black !important;
    overflow: hidden !important;
    border-radius: .25em !important;
    color: white !important;
}
select {
    flex: 1 1 !important;
    padding: 0 .5em !important;
    color: #fff !important;
    cursor: pointer !important;
    color: white !important;
}
/* Arrow */
.select::after {
    content: '\25BC' !important;
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    padding: 0 1em !important;
    background: #34495e !important;
    cursor: pointer !important;
    pointer-events: none !important;
    transition: .25s all ease !important;
    color: white !important;
}
/* Transition */
.select:hover::after {
    color: #f39c12 !important;
}


.tick-icon {
    color: #ffcf0d;
}

.job-price-card {
    height: 600px !important;
}
.job-pricing-choose-card {
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
}

.job-price-h5 {
    font-size: 15px !important;
}

input[type=checkbox] {
    border: 0px;
    margin: 0px;
    width: 10%;
}



.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}

    .ribbon::before,
    .ribbon::after {
        position: absolute;
        z-index: -1;
        content: '';
        display: block;
        border: 5px solid #28abb9;
    }

    .ribbon span {
        position: absolute;
        display: block;
        width: 225px;
        padding: 15px 0;
        background-color: #28abb9;
        box-shadow: 0 5px 10px rgba(0,0,0,.1);
        color: #fff;
        font: 700 18px/1 'Lato', sans-serif;
        text-shadow: 0 1px 1px rgba(0,0,0,.2);
        text-transform: uppercase;
        text-align: center;
    }



/* top right*/
.ribbon-top-right {
    top: -10px;
    right: -10px;
}

    .ribbon-top-right::before,
    .ribbon-top-right::after {
        border-top-color: transparent;
        border-right-color: transparent;
    }

    .ribbon-top-right::before {
        top: 0;
        left: 0;
    }

    .ribbon-top-right::after {
        bottom: 0;
        right: 0;
    }

    .ribbon-top-right span {
        left: -25px;
        top: 30px;
        transform: rotate(45deg);
    }



.jb::-webkit-input-placeholder {
    font-weight: bold;
}
.jb::-moz-placeholder {
    font-weight: bold;
}
.jb:-ms-input-placeholder {
    font-weight: bold;
}
.jb:-o-input-placeholder {
    font-weight: bold;
} 