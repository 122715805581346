.NavigationItem {
    margin: 10px 0;
    padding-top: 8px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: auto;
    height: 100%;
}

.NavigationItem a {
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    color: black;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    border-bottom: 4px solid black;
    color: rgb(190, 77, 35);
}
