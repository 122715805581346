.Navbar_Items {
    position: -webkit-sticky;
    position: sticky;
    top:0px;
    margin: 0;
    padding: 0;
    padding-left: 30px;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 70px;
    width: 100%;
    background-color: #ffcf0d;
    z-index: 10000
    
    /*display:inline-block;*/
    
}


.Navbar_Items a:hover {
    font-weight: normal !important;
}





@media (min-width: 500px) {
    .Navbar_Items {
    flex-flow: row;
  }
}
