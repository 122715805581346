



.form-container {
    padding-top: 50px !important;
    max-width: 900px !important;
    padding-bottom: 30px !important;
    margin: 50px auto !important;
    border-radius: 8px !important;
    background-color: white !important;
    border-radius: 10px !important;
}

.form-control {
    min-height: 60px !important;
    min-width: 50px !important;
    display: flex !important;
    align-items: center !important;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3) !important;
    padding-left: 10px !important;
}

.submit {
    text-align: center;
    background-color: #ffcf0d;
    font-weight: bold !important;
    color: black !important;
}




.heading {
    text-align: center;
}

.tag {
    color: gray !important;
    font-size: 15px !important;
}
