.app-table {
    background-color: #fff;
   
    margin: 10px;
    padding: 10px 20px 10px 30px;
    
    width: auto;
    align-items: center;
    border-radius: 10px;
    font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans" "Helvetica Neue", sans-serif;
  }
  /* .backI {
    background-color:rgba(0,0,0,0.1);
    height:100vh;
  } */
  
  .progress-bar{
   background-color: #FECE0B;
  }
  
  
  .main-table tr:hover {
    background-color: rgb(255 207 13 / 34%);
    color: black;
    cursor: pointer;
  }
  
  .main-table .ele-type button {
    width: 100px;
    height: 25px;
    border: none;
    border-radius: 30px;
    background-color: #FECE0B;
    color: #231B1B;
  }
  
  
  
  button[disabled=disabled], button:disabled {
  
    color: rgba(0,0,0);
  }
  
  small{
    color:black;
  }
  .pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    
  }
  .pagination > .active > a {
    background-color: #FECE0B;
    border-color: #ffcf0d;
    color: #231B1B;
  }
  .pagination > li > a {
    border: 1px solid #ffcf0d;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    color: #231B1B;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    background-color: #ffcf0d;
    border-color: #ffcf0d;
    outline: none;
  }
  .pagination > li > a,
  .pagination > li > span {
    color: grey;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span,
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-radius: unset;
  }
  
  .aligncenter {
      text-align: center;
  }
  
  img {
    opacity: 0.7;
  }