
.img1 {
 
    border-radius: 50px;
    background-color: #ffcf0d;
    
}
.pstyle {
  
    font-weight: bold;
    font-family: 'Noto Sans SC', sans-serif;
    
}
.pstyle2 {
    font-family: 'Noto Sans SC', sans-serif;
}

.divcircular {
    background-color: rgb(255, 255, 255);
    border-radius:30px ;
  
   
    height:120px;
  
    box-shadow:  0 0 1px black;
    
}
.thirdC {
  margin-top: 200px;
  /* margin-left: 80px; */
    /* background-size: 70%;
    background-repeat: no-repeat;
    background-origin: border-box; */
}
/* .divcircular div {
    padding:0px;
    margin:0px;
} */
.divcircular2 {
    margin-top: 80px;
}
.coldiv {
  
    margin-top: 80px;
}
.seconddiv {
    margin-top: 130px;
}
.mobileimage {
    background-image: url("../Utils//images//Mobile.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 500px 600px 
    /* background-size: 640px 760px; */
}
.col2div {
   
    /* background-image: url('../Utils/images/MobileMInimalSeparate.png'); */
    background-image: url('../Utils/images/MobileUpdated.png');
  
    background-repeat: no-repeat;
    background-position-x: 5vw;
    background-position-y: 5vw ;
    
    background-size: 600px 600px;
    
  
   

}
.divState{
    background-color: rgb(255, 255, 255);
   height:230px;
   /* width:180px; */
   box-shadow:  0 0 1px black;
   border-radius: 40px;
}
.s1 {
    /* background-image: url("../Utils//images//MapWithLocationIcon.png"); */
    background-image: url("../Utils//images//MapWithLocationUpdated.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 640px 600px
    
    
}
